import { template as template_c9d571fe8a044913be80e208ba05f916 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_c9d571fe8a044913be80e208ba05f916(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
