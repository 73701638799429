import { template as template_534fae50d61d4cacb3064f80d68c14a8 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_534fae50d61d4cacb3064f80d68c14a8(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
