import { template as template_90b25ad8bd774090b3a7b40762d8e80b } from "@ember/template-compiler";
const FKText = template_90b25ad8bd774090b3a7b40762d8e80b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
