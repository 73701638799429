import { template as template_657067930dad41f09a1a285e74d57ee9 } from "@ember/template-compiler";
const FKLabel = template_657067930dad41f09a1a285e74d57ee9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
